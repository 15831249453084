import revive_payload_client_4sVQNw7RlN from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/doxa-frontend/doxa-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import script_YrMPz5ICtR from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_t2GMTTFnMT from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_client_KZ0f4ARq4r from "/home/runner/work/doxa-frontend/doxa-frontend/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import mobile_detect_client_I6x6j4jIeU from "/home/runner/work/doxa-frontend/doxa-frontend/plugins/mobile-detect.client.js";
import sanity_image_builder_bQsAocKPDm from "/home/runner/work/doxa-frontend/doxa-frontend/plugins/sanity-image-builder.js";
import scroll_08MQiDypDv from "/home/runner/work/doxa-frontend/doxa-frontend/plugins/scroll.js";
import sentry_client_GoGQuZo4Et from "/home/runner/work/doxa-frontend/doxa-frontend/plugins/sentry.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  script_YrMPz5ICtR,
  plugin_t2GMTTFnMT,
  plugin_client_KZ0f4ARq4r,
  mobile_detect_client_I6x6j4jIeU,
  sanity_image_builder_bQsAocKPDm,
  scroll_08MQiDypDv,
  sentry_client_GoGQuZo4Et
]
import * as Sentry from '@sentry/vue';
import { defineNuxtPlugin, useRouter, useRuntimeConfig } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig();

  if (!sentry.dsn || sentry.environment === '') return;

  const isStaging = sentry.environment !== 'production';

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment, // staging or production
    release: sentry.release, // branch name
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    tracesSampleRate: isStaging ? 1 : 0.8,

    tracePropagationTargets: [
      'localhost',
      /https:\/\/doxa.team/,
      /http:\/\/.+\.process\.doxa-frontend-stg\.internal:3000/,
    ],

    ignoreErrors: [
      /\[Cloudflare Turnstile]/, // ignore turnstile client-side verification errors
    ],

    replaysSessionSampleRate: isStaging ? 0 : 0.02, // ignore replays for staging environment
    replaysOnErrorSampleRate: 0.5,
  });
});
